html,body{
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}
.body {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: PingFang-SC-Medium,sans-serif;
  color: #333333;
}
.header {
  width: 100%;
  height: 100px;
  box-shadow: 0 2px 4px 0 rgba(255,227,227,0.50);
  z-index: 1;
  margin-bottom: 70px;
}
.header-logo {
  width: 210px;
  height: 130px;
  background: url("./img/logo-bottom.png") #ffffff no-repeat center;
  z-index: 10;
  position: relative;
  margin-right: 25px;
}
.logo{
  width: 190px;
  height: 104px;
  margin-top: 20px;
  background: url("./img/logo.png") no-repeat center;
}
.header-tabs {
  width: 890px;
  height: 40px;
  background-color: #ffffff;
}
.tab{
  background-color: #ffffff;
  position: relative;
  height: 40px;
  margin: 0 15px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}
.tab-text{
  text-align: center;
  position: relative;
  height: 40px;
  line-height: 40px;
  z-index: 10;
  letter-spacing: 0.86px;
}
.tab-indicator{
  position: absolute;
  bottom: 0;
  height: 20px;
  width: 100%;
  background-color: #F6BAB9;
  z-index: 1;
}
.header-right {
  width: 50px;
  height: 50px;
  background: url("./img/more.png") no-repeat center;
  margin-left: 25px;
}
.navigation{
  width: 100%;
  height: 160px;
  margin-bottom: 70px
}
.navigation-item {
  padding: 5px;
}
.navigation-item img {
  width: 250px;
  height: 150px;
}
.navigation-text{
  width: 280px;
  margin-left: 10px;
}
.navigation-text-title{
  font-size: 24px;
  color: #E31C17;
  line-height: 35px;
  letter-spacing: -0.58px;
  margin-bottom: 5px;
}
.navigation-text-detail{
  font-size: 20px;
  color: #666666;
  letter-spacing: -0.48px;
  margin-bottom: 3px;
}
.navigation-text-detailEN{
  font-family: ArialMT-woff,sans-serif;
  font-size: 20px;
  color: #666666;
  letter-spacing: -0.48px;
}
.conduction1{
  width: 100%;
  height: 1078px;
  background: url("./img/background1.png") no-repeat left;
}
.conduction1 .left-text{
  position: relative;
  margin-left: 100px;
  margin-top: 40px;
}
.conduction1 .left-img{
  position: relative;
  margin-top: 180px;
}
.conduction1 .right-scan{
  height: 440px;
  width: 700px;
  background: url("./img/background2.png") no-repeat bottom;
}
.conduction2{
  width: 100%;
  height: 1078px;
  background: url("./img/background3.png") no-repeat center bottom;
}
.condition-layout{
  width: 50%;
  position: relative;
}
.conduction2 .left-title{
  position: relative;
  margin-top: 70px;
  margin-right: 360px;
}
.conduction2 .left-text{
  position: relative;
  margin-top: 50px;
  margin-right: 50px;
}
.conduction2 .left-img{
  position: relative;
  margin-top: 80px;
  margin-right: 50px;
}
.conduction3 {
  width: 100%;
  height: 1380px;
  position: relative;
}
.conduction4{
  width: 100%;
  height: 855px;
  background: url("./img/background4.png") no-repeat right;
  margin-top: 150px;
}
.conduction5{
  width: 100%;
  height: 1331px;
  background: url("./img/background5.png") no-repeat right;
  margin-top: 140px;
}
.conduction6{
  width: 100%;
  height: 1100px;
  background: url("./img/background6.png") no-repeat right top;
}
.pop-parent{
  position: absolute;
  top: -55px;
  left: -98px;
  width: 250px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(227,28,23,0.20);
}
.pop-parent:after {
  position: absolute;
  display: inline-block;
  top: 35px;
  left: 118px;
  width: 0;
  height: 0;
  content: '';
  border-style: solid;
  border-width: 5px;
  border-color: #fff #fff transparent transparent;
  transform: rotate(135deg);
  box-shadow: 2px -2px 2px  rgba(227,28,23,0.20);
}
.pop-text{
  font-family: PingFang-SC-Regular,sans-serif;
  font-size: 18px;
  color: #666666;
  letter-spacing: 0.64px;
  text-align: center;
}
.conduction7{
  width: 100%;
  height: 520px;
  background-color: #8C8888;
}
.conduction7-title{
  font-family: PingFang-SC-Regular,sans-serif;
  font-size: 36px;
  color: #FFFFFF;
  letter-spacing: 1.29px;
}
.conduction7-images img{
  float: left;
  margin-right: 75px;
  margin-bottom: 40px;
  width: 180px;
  height: 70px;
}
.conduction7-images img:nth-child(5n){
  margin-right: 0;
}
.footer{
  width: 100%;
  height: 90px;
  line-height: 15px;
  background: #736F6F;
  padding: 30px 0;
}
.footer-title{
  font-family: PingFang-SC-Regular,sans-serif;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0.64px;
  text-align: center;
}
.banner{
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.banner img{
  width: 100%;
  min-width: 1200px;
  height: 100%;
}
.banner .swiper-button-prev-banner {
  background-image: url(./img/arrow-left-big.png)!important;
  left: 0!important;
  width: 130px!important;
  background-size: 120px 120px!important;
  outline: none;
  position: absolute;
  top: 50%;
  height: 120px;
  margin-top: -60px;
  z-index: 10;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.banner .swiper-button-next-banner {
  position: absolute;
  background-image: url(./img/arrow-right-big.png);
  right: 0;
  width: 130px;
  background-size: 120px 120px;
  outline: none;
  top: 50%;
  height: 120px;
  margin-top: -60px;
  z-index: 10;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.banner.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
  bottom: 50px!important;
}
.banner.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 10px!important;
}
.banner .swiper-pagination-bullet-active {
  background: #E31C17!important;
}
.banner .swiper-pagination-bullet{
  width: 15px!important;
  height: 15px!important;
  background: #999999;
}
.gallery{
  position:absolute;
  top:675px;
  height: 640px;
  width: 100%;
  background-color: transparent;
  z-index: 10
}
.gallery .swiper-container1 {
  width: 100%;
  height: 100%;
}
.gallery .swiper-container1 .swiper-slide {
  background: #fff;
  width: auto;
}
.banner {
  width: 100%;
  height: 1200px;
}
.gallery2 {
  width: 1200px;
  position: relative;
  margin: 0 auto;
  height: 175px;
  overflow: hidden;
}
.swiper-container2 {
  width: 1080px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.swiper-container2  .swiper-slide {
  width: 320px;
  height: 175px;
  background: #fff;
  border: solid 2px #F4F0F0;
}
.gallery2 .swiper-button-next {
  background-image: url("./img/arrow-right.png")!important;
  right: 0!important;
  width: 44px!important;
  background-size: 44px 44px!important;
  outline: none;
}
.gallery2 .swiper-button-prev {
  background-image: url("./img/arrow-left.png")!important;
  left: 0!important;
  width: 44px!important;
  background-size: 44px 44px!important;
  outline: none;
}





